import React from 'react';
import { reduxForm } from 'redux-form';

import { FormGroupField } from 'tyb';
import { Input, Dialog, FormGroupList } from 'tyb';
import './AddOrEditTagMaterialForm.scss';

const required = (value) => {
  return (!value || (value + '').trim() === '' ? '请填写名称' : undefined);
  // return (value || typeof value === 'number' ? undefined : '请填写备注');
}

const max = (max) => (value) => {
  return value && value.trim().length > max ? `至多${max}字符` : undefined
}

const numInvalid = (value) => {
  const p = /[0-9]/;
  const result = p.test(value);
  if (result) {
    return '不可输入数字'
  } 
  return undefined;
}

const symbolInvalid = (value) => {
  const  p = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
  const result = p.test(value);
  if (result) {
    return '不可输入符号'
  }
  return undefined
}

const maxLength30 = max(30);

const AddOrEditTagMaterialForm = props => {
  const { onSubmit, handleSubmit, handleCancel, tagMaterialFormType } = props;
    // if (tagMaterialFormType === 'edit') {
    //   console.log(editTagMatName);
    //   change('name', editTagMatName)
    // }
  return (
    <Dialog
          className="AddOrEditTagMaterialForm"
          title={tagMaterialFormType === 'edit' ? '编辑赋码材料' : '新增赋码材料'}
          visible={true} 
          okText="确认"
          cancelText="取消"
          onOk={handleSubmit(onSubmit)}
          onCancel={handleCancel}
        >
      <div className="main-block">
        <FormGroupList>
          <FormGroupField 
            name="name"
            component={Input}
            label="赋码材料名称"
            validate={[required, maxLength30, numInvalid, symbolInvalid]}
          >
            <span className="extra-text">
              支持中文、英字母、不可输入数字和符号，长度不超过30个字符
            </span>
          </FormGroupField>
        </FormGroupList>
        
      </div>
      
    </Dialog>
  )
}

export default reduxForm({
  form: 'AddOrEditTagMaterialForm',
})(AddOrEditTagMaterialForm);